import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { NgxPermissionsService } from 'ngx-permissions';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { environment } from 'src/environments/environment';
import { SessionExpirationService } from './shared/service/session-expiration.service';
import { Keepalive } from '@ng-idle/keepalive';
import { Subscription } from 'rxjs';

const DEFAULT_IDLE_TIME_IN_SECONDS: number = 3600;
const IDLE_TIME_IN_SECONDS: number =
  parseInt(environment.idleTimeInSeconds) || DEFAULT_IDLE_TIME_IN_SECONDS;

const DEFAULT_KEEP_ALIVE_INTERVAL = 15;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Portail HubEE';
  isClosingSidebar: boolean;
  groups: string[];

  subscriptions: Subscription[] = [];

  constructor(
    private translate: TranslateService,
    private permissionsService: NgxPermissionsService,
    private oauthService: OAuthService,
    private keepAliveService: Keepalive,
    private idle: Idle,
    private sessionExpirationService: SessionExpirationService
  ) {
    // Verifie dans la session, si l'utilisateur
    // a une session expiré. Stock en session pour
    // vérifier même après un refresh de page
    let isSessionExpiredItem = sessionStorage.getItem(
      this.sessionExpirationService.IS_SESSION_EXPIRED_KEY
    );
    let isSessionExpired =
      isSessionExpiredItem && isSessionExpiredItem.toLowerCase() === 'true';
    if (isSessionExpired) {
      sessionStorage.setItem(
        this.sessionExpirationService.IS_SESSION_EXPIRED_KEY,
        'false'
      );
      this.oauthService.logOut();
    }
    translate.setDefaultLang('fr');
    translate.use('fr');

    this.initIdleConfiguration();

    this.reset();
  }

  private initIdleConfiguration() {
    // Lance le idle au bout de IDLE_TIME_IN_SECONDS secondes
    this.idle.setIdle(IDLE_TIME_IN_SECONDS);
    // Set le timeout à 1 seconde
    this.idle.setTimeout(1);

    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    const idleEndSubscription = this.idle.onIdleEnd.subscribe(() => {
      console.log('No longer idle');
    });

    this.subscriptions.push(idleEndSubscription);

    const timeOutSubscription = this.idle.onTimeout.subscribe(() => {
      this.sessionExpirationService.setIsSessionExpired(true);
      this.sessionExpirationService.openDialog(() => {
        this.oauthService.logOut();
      });
    });

    this.subscriptions.push(timeOutSubscription);

    this.keepAliveService.interval(DEFAULT_KEEP_ALIVE_INTERVAL);
  }

  reset() {
    this.idle.watch();
  }

  ngOnInit(): void {
    this.loadPermissionsUserConnected();
  }

  public onToggleSidebarClicked(isSidebarOpen: boolean): void {
    this.isClosingSidebar = !isSidebarOpen;
  }

  loadPermissionsUserConnected(): void {
    const claims: any = this.oauthService.getIdentityClaims();
    if (claims) {
      this.groups = claims.groups;
      this.permissionsService.loadPermissions(this.groups);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
